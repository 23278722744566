export const LOGGED_IN_HOME_PATH = '/accueil';
export const CONNEXION_PATH = '/connexion';
export const MOT_DE_PASSE_OUBLIE_PATH = '/mot-de-passe-oublie';

export const LOGGED_OUT_HOME_PATH = '/';
export const LOG_OUT_PATH = '/deconnexion';
export const LANDING_PAGE_LYCEEN = '/lyceen';
export const LANDING_PAGE_EQUIPE_PEDAGOGIQUE = '/equipe-pedagogique';
export const LANDING_PAGE_ETUDIANT = '/etudiant';

export const PEDAGOGIE_REDIRECTION_LANDING = '/pedagogie';

export const INSCRIPTION_PATH = '/inscription';
export const INSCRIPTION_PROF_PATH = `${INSCRIPTION_PATH}/prof`;
export const INSCRIPTION_PROF_CLASSE_PATH = `${INSCRIPTION_PROF_PATH}/classe`;
export const INSCRIPTION_PROF_COMPTE_PATH = `${INSCRIPTION_PROF_PATH}/compte`;
export const INSCRIPTION_ECLAIREUR_PATH = `${INSCRIPTION_PATH}/eclaireur`;
export const INSCRIPTION_ECLAIREUR_PROFILE_PATH = `${INSCRIPTION_ECLAIREUR_PATH}/profil`;

export const VALIDATION_EMAIL_PATH = '/validation-email';

export const ACCEUIL_SECONDE_COLLEGE = '/accueil-seconde-college';

export const INSCRIPTION_LYCEEN_PATH = `${INSCRIPTION_PATH}/lyceen`;
export const REJOINDRE_UNE_CLASSE_PATH = '/rejoindre-une-classe';

export const MA_CLASSE_PATH = '/ma-classe';
export const MES_ELEVES_PATH = '/mes-eleves';
export const MES_MODULES_PATH = '/mes-modules';

export const CREER_UNE_CLASSE_PATH = '/creer-une-classe';

const INFOS_LEGALES_PATH = '/infos-legales';
export const CONFIDENTIALITE_PATH = `${INFOS_LEGALES_PATH}/confidentialite`;
export const MENTIONS_LEGALES_PATH = `${INFOS_LEGALES_PATH}/mentions-legales`;

export const ECLAIREURS_PATH = '/eclaireurs';
export const ECHANGER_ECLAIREUR_PATH = '/echanger-avec-les-eclaireurs';
export const DEVENIR_ECLAIREUR_PATH = '/devenir-eclaireur';
export const QUESTIONNAIRE_PATH = '/questionnaire';
export const ECLAIREURS_MOBILITE_GROUP_PATH = '/eclaireurs-mobilite';

export const ARTICLES_PATH = '/actualites';

export const PROFIL_ECLAIREUR_PATH = '/profil-eclaireur';
export const CHAT_PATH = '/echanges';
export const CHARTE_ECLAIREUR_PATH = '/charte-eclaireur';
export const ETRE_ECLAIREUR_PATH = '/etre-eclaireur';
export const GROUPE_ECLAIREUR_PATH = '/groupe-eclaireur';

export const QUI_SOMMES_NOUS_PATH = '/qui-sommes-nous';
export const PRINTEMPS_ORIENTATION_PATH = '/printemps-orientation';

export const ATELIERS_PATH = '/ateliers';

export const FAQ_PATH = '/faq';

export const PISTES_PATH = '/pistes';
export const PISTES_INSERTION_PATH = '/pistes#insertion';

export const FICHES_ACTIVITES_PATH = '/fiches-activites';
export const KIT_DE_SURVIE_PATH = '/kit-de-survie';
export const MES_REPONSES_PATH = '/mes-reponses';

export const STYLEGUIDE_PATH = '/debug/styleguide';

export const articlePath = (slug: string) => `/blog/${slug}`;
export const actualitesPath = (tag: string) => `/actualites/${tag}`;
export const modulePath = (id: string) => `/module/${id}`;
export const moduleEditPath = (id: string) => `/module/editer/${id}`;
export const activitePath = (moduleId: string, activiteId: string) =>
  `/module/${moduleId}/${activiteId}`;
export const activiteStepPath = (
  moduleId: string,
  activiteId: string,
  step: number
) => `/module/${moduleId}/${activiteId}/${step}`;
export const activiteEndPath = (moduleId: string, activiteId: string) =>
  `/module/${moduleId}/${activiteId}/fin`;

export const questionnaireStepPath = (step: number) => `/questionnaire/${step}`;
export const pistePath = (slug: string) => `/piste/${slug}`;
export const chatThreadPath = (threadId: string) => `/echange/${threadId}`;
export const elevePath = (id: string) => `/eleve/${id}`;
export const lyceenPath = (id: string) => `/lyceen/${id}`;
export const eclaireursPath = (searchParams: {
  filieres: any[];
  experiences: any[];
  regions: any[];
  typesFormation: any[];
  showFavorites: boolean;
}) =>
  `/eclaireurs/?filieres=${searchParams.filieres}&experiences=${searchParams.experiences}&regions=${searchParams.regions}&typesFormation=${searchParams.typesFormation}&showFavorites=${searchParams.showFavorites}`;
export const eclaireurPath = (id: string) => `/eclaireur/${id}`;
export const recherchePath = (q: string, entity?: string) =>
  `/recherche/${entity || 'pistes'}?q=${q}`;
export const signupTokenLyceenPath = (signupToken: string) =>
  `${INSCRIPTION_PATH}/${signupToken}`;
export const kitDeSurviePath = (category: string) =>
  `/kit-de-survie/${category}`;

export const monCompteTabPath = (tag: string) => `/mon-compte/${tag}`;
export const MON_PROFIL_PATH = monCompteTabPath('mon-profil');
export const MA_CLASSE_COMPTE_PATH = monCompteTabPath('ma-classe');
export const ATELIER_SUP_PATH = monCompteTabPath('atelier-sup');
export const PREFERENCES_PATH = monCompteTabPath('preferences');

export const getAbsolutePath = (path = '') => {
  const url = `${
    process.env.NEXT_PUBLIC_BASE_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL
  }${path}`;
  return url.startsWith('http') ? url : `https://${url}`;
};
