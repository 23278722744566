import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import { exists } from '@v1v2/util';
import { isEqual } from 'lodash';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import { Session } from 'next-auth';
import { Provider as NextAuthProvider } from 'next-auth/client';
import NProgress from 'nprogress';
import TagManager from 'lib/packages/react-gtm-module/TagManager';
import { Helmet } from 'react-helmet';
import {
  QueryClient as ReactQueryClient,
  QueryClientProvider as ReactQueryProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { useMount } from 'react-use';

import { DepsToRegions } from '@inspire/data/static/regions';
import { Departments } from '@inspire/data/static/departments';
import { StaticHelpers } from '@inspire/data/helpers/static';
import theme from '@inspire/ui/chakra/theme';
import favicons from '@inspire/ui/favicons';

import 'focus-visible/dist/focus-visible';

import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';

import '@inspire/ui/styles/global/_foundation5-sass/_normalize.scss';
import '@inspire/ui/chakra/chakra.css';
import 'styles/fonts.css';

import { useClasse, useMyProfile, useUser } from 'lib/hooks';
import { pushAsyncPageView } from 'lib/gtm';
import {
  EclaireurProfileRedirect,
  LyceenQuestionnaireRedirect,
} from 'components/auth/Redirects';
import LyceeStudyModal from 'components/lyceen/LyceeStudyModal';
import Crisp from 'components/utils/Crisp';
import Hotjar from 'components/utils/Hotjar';
import { getCookie, setCookie } from 'lib/cookies';
import { v4 } from 'uuid';
import { useRouter } from 'next/router';
import { loadGTM } from 'lib/trackers';
import { QUESTIONNAIRE_PATH } from 'lib/paths';
import { SHA256 } from 'lib/hash-password';

// https://nextjs.org/docs/basic-features/layouts

type NextPageWithLayout = NextPage & {
  getWrappers?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<{ session: Session }> & {
  Component: NextPageWithLayout;
};

const reactQueryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  },
});

NProgress.configure({
  minimum: 0.2,
  easing: 'ease',
  speed: 500,
  showSpinner: false,
});

let lastUrl: string;
let progressTimer;
let cookieSet = false;

Router.events.on('routeChangeStart', (url) => {
  if (url !== lastUrl) {
    lastUrl = url;
    progressTimer = setTimeout(() => NProgress.start(), 300);
  }
});
Router.events.on('routeChangeComplete', () => {
  clearTimeout(progressTimer);
  NProgress.done();
});
Router.events.on('routeChangeError', () => {
  clearTimeout(progressTimer);
  NProgress.done();
});

interface DataLayer {
  user?: {
    role?: 'lyceen' | 'eclaireur' | 'pedagogie' | 'guest' | undefined;
    isLoggedIn?: boolean;
    visitorId?: string;
    accountId?: string;
    ssoId?: string;
    gender?: 'male' | 'female' | 'other' | 'doesnt_want_to_answer';
    birthDate?: Date;
    bacType?: string;
    pisteName?: string;
    isBoursierSecondaire?: string; // oui, non, ne-sais-pas
    wasEclaireurBoursierLycee?: string; // oui, non, ne-sais-pas
    hadInspirePresentation?: boolean;
    isLyceenPrioritaire?: boolean;
    niveau?: string;
    serieTechno?: string;
    familleMetiersPro?: string;
    specialitePro?: string;
    departement?: string;
    classeNameAndId?: string;
    academie?: string;
    didAtelierVLS?: boolean;
    region?: string;
    email?: string; // hash of the email with the SHA256 algorithm
  };
}

const GlobalWrapper = ({ children }: { children: ReactNode }) => {
  const [hasAsyncPageViewBeenFired, setHasAsyncPageViewBeenFired] =
    useState(false);
  const { user, isProf, isLyceen, isEclaireur, isUserLoading, session } =
    useUser();
  const { classe, isClasseLoading } = useClasse();
  const { myProfile, isMyProfileLoading, ssoId } = useMyProfile({
    enabled: Boolean(user),
  });
  const {
    isOpen: isNiveauModalOpen,
    onOpen: openNiveauModal,
    onClose: closeNiveauModal,
  } = useDisclosure();
  const [isUserDataLayerReady, setIsUserDataLayerReady] =
    useState<boolean>(false);

  const { pathname } = useRouter();

  useEffect(() => {
    loadGTM();
    if (session === null) {
      TagManager.dataLayer({
        dataLayer: { user: { role: 'guest', isLoggedIn: false } },
      });
    }
  }, [session]);

  useEffect(() => {
    const dataLayer: DataLayer = { user: {} };

    // visitor ID
    const visitorId = getCookie('visitorId');
    dataLayer.user.visitorId = visitorId;

    if (myProfile.email) {
      dataLayer.user.email = SHA256(myProfile.email.trim().toLowerCase());
    }

    if (user) {
      dataLayer.user.isLoggedIn = true;
      // Inspire ID
      dataLayer.user.accountId = user.id;
      // SSO ID
      dataLayer.user.ssoId = ssoId;

      if (isLyceen) {
        dataLayer.user.role = 'lyceen';
      } else if (isEclaireur) {
        dataLayer.user.role = 'eclaireur';
      } else if (isProf) {
        dataLayer.user.role = 'pedagogie';
      } else {
        dataLayer.user.role = 'guest';
      }
      setIsUserDataLayerReady(true);
    }

    if (isLyceen || isEclaireur) {
      // gender
      if (myProfile.gender === 'garcon' || myProfile.title === 'mr') {
        dataLayer.user.gender = 'male';
      } else if (myProfile.gender === 'fille' || myProfile.title === 'mme') {
        dataLayer.user.gender = 'female';
      } else if (myProfile.gender === 'autre') {
        dataLayer.user.gender = 'other';
      } else if (myProfile.gender === 'ne_souhaite_pas_repondre') {
        dataLayer.user.gender = 'doesnt_want_to_answer';
      }

      // birthdate
      if (myProfile.birthDate) {
        dataLayer.user.birthDate = myProfile.birthDate;
      }
    }

    if (
      myProfile.pistes &&
      myProfile.pistes.length > 0 &&
      myProfile.pistes[0].name
    ) {
      dataLayer.user.pisteName = myProfile.pistes[0].name;
    }

    if (isLyceen && myProfile.filiere) {
      dataLayer.user.bacType = myProfile.filiere;
    }

    if (myProfile.filiereTechno) {
      dataLayer.user.serieTechno = myProfile.filiereTechno;
    }

    // 'oui', 'non', 'ne-sais-pas'
    if (myProfile.isBoursierSecondaire) {
      dataLayer.user.isBoursierSecondaire = myProfile.isBoursierSecondaire;
    }
    // 'oui', 'non', 'ne-sais-pas'
    if (myProfile.boursierLycee) {
      dataLayer.user.wasEclaireurBoursierLycee = myProfile.boursierLycee;
    }

    if (isProf && exists(myProfile.hadInspirePresentation)) {
      dataLayer.user.hadInspirePresentation = myProfile.hadInspirePresentation;
    }

    if (exists(myProfile.isLyceenPrioritaire)) {
      dataLayer.user.isLyceenPrioritaire = myProfile.isLyceenPrioritaire;
    }

    if (myProfile.niveau) {
      dataLayer.user.niveau = myProfile.niveau;
    }

    if (myProfile.referredBy === 'atelier') {
      dataLayer.user.didAtelierVLS = true;
    }

    const department =
      myProfile.departement ??
      (myProfile.codePostal &&
        StaticHelpers.getDepartmentFromPostalCode(myProfile.codePostal)) ??
      null;

    if (department) {
      dataLayer.user.departement = `${department} - ${Departments[department]}`;
      dataLayer.user.region = DepsToRegions[department];
    }

    if (myProfile.region) {
      dataLayer.user.region = myProfile.region;
    }

    // myProfile always contains some data, even when loading, so we cannot wrap this
    // whole effect block into an `if (myProfile)`. That's why we only push to the data layer
    // if things have been added to it in the effect compared to the default empty object.
    if (!isEqual(dataLayer, { user: {} })) {
      TagManager.dataLayer({ dataLayer });
    }
  }, [JSON.stringify(myProfile)]);

  useEffect(() => {
    if (classe) {
      const dataLayer: DataLayer = { user: {} };

      // Example: 'Seconde 4 - 8fy427yf724h972h'
      dataLayer.user.classeNameAndId = `${classe.classeName} - ${classe._id}`;

      if (classe.familleMetiers) {
        dataLayer.user.familleMetiersPro = classe.familleMetiers;
      }

      if (classe.filiere) {
        dataLayer.user.specialitePro = classe.filiere;
      }

      if (classe.lycee?.departement && classe.lycee?.departementCode) {
        // Example: '01 - Ain'
        dataLayer.user.departement = `${classe.lycee.departementCode} - ${classe.lycee?.departement}`;
        dataLayer.user.region = DepsToRegions[classe.lycee.departementCode];
      }

      if (classe.lycee?.academie) {
        dataLayer.user.academie = classe.lycee.academie;
      }

      TagManager.dataLayer({ dataLayer });
    }
  }, [JSON.stringify(classe)]);

  // This effect is triggered only once per full page refresh (not trigged
  // on client navigation), and only if all the data is done loading.
  // If we instead used a regular GTM built-in Page View, it would happen
  // before data had the time to fetch.
  // The hasAsyncPageViewBeenFired state is just here to make sure
  // we only fire it once.
  useEffect(() => {
    if (
      !hasAsyncPageViewBeenFired &&
      !isUserLoading &&
      !isMyProfileLoading &&
      !isClasseLoading &&
      isUserDataLayerReady
    ) {
      pushAsyncPageView();
      setHasAsyncPageViewBeenFired(true);

      // If the user is linked to an obendy account, we need to load
      // the obendy widget and analytics script.
      if (myProfile.partnership === 'obendy') {
        // Add a script that will present the user with a widget
        if (myProfile.obendyWidgetUrl) {
          const script = document.createElement('script');
          script.type = 'text/javascript';

          script.textContent = `
          function test(e, a) {
            var n = document.createElement("script");
            var c = Date.now();
            var o = a.indexOf("?") === -1 ? "?" : "&";
            n.src = a + o + "z=" + c;
            var i = document.getElementsByTagName("script")[0];
            var m = i.parentElement;
            m.insertBefore(n, i);
          }; test(window, '${myProfile.obendyWidgetUrl}');
          `;

          document.head.appendChild(script);
        }

        // Add a script that will send analytics data to obendy
        if (myProfile.obendyPixelAnalyticsUrl) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.innerHTML = `(function(e,a){var n=document.createElement("script");var c=Date.now();var o=a.indexOf("?")===-1?"?":"&";n.src=a+o+"z="+c;var i=document.getElementsByTagName("script")[0];var m=i.parentElement;m.insertBefore(n,i)})(window, '${myProfile.obendyPixelAnalyticsUrl}');`;

          document.head.appendChild(script);
        }
      }
    }
  }, [
    hasAsyncPageViewBeenFired,
    isUserLoading,
    isMyProfileLoading,
    isClasseLoading,
    isUserDataLayerReady,
  ]);

  useEffect(() => {
    // if isNiveauConfirmed is null, it means lyceeStudies does not
    // contain any entry. It is probably a new user, so we don't show
    // the popup. The user will enter their niveau via the questionnaire.
    if (
      isLyceen &&
      myProfile.isNiveauConfirmed === false &&
      pathname !== QUESTIONNAIRE_PATH
    ) {
      openNiveauModal();
    }
  }, [isLyceen, myProfile.isNiveauConfirmed]);

  return (
    <EclaireurProfileRedirect>
      <LyceenQuestionnaireRedirect>
        <LyceeStudyModal
          isOpen={isNiveauModalOpen}
          closeModal={closeNiveauModal}
        />
        {children}
      </LyceenQuestionnaireRedirect>
    </EclaireurProfileRedirect>
  );
};

// @ts-ignore
const App = ({ Component: Page, pageProps, err }: AppPropsWithLayout) => {
  const getWrappers = Page.getWrappers ?? ((page) => page);

  const router = useRouter();
  useEffect(() => {
    if (!router.isReady || cookieSet) {
      return;
    }
    cookieSet = true;
    // Create and set the visitor cookie using a query param
    const query = router.query;
    let visitorId = '';
    if (query.visitorId) {
      visitorId = '' + query.visitorId;
      setCookie('visitorId', visitorId, 1);

      // Remove the query param from the URL so it is transparent for the user
      const removeQueryParam = () => {
        const { pathname } = router;
        const params: any = { ...query };
        delete params.visitorId;
        router.replace({ pathname, query: params }, undefined, {
          shallow: true,
        });
      };
      removeQueryParam();
    } else {
      visitorId = v4();
      setCookie('visitorId', visitorId, 1);
    }
  });

  useMount(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register('/sw.js');
    }
  });

  const title = 'INSPIRE - Orientation Post-Bac';

  const description =
    'INSPIRE permet aux lycéens de préparer leurs voeux Parcoursup ' +
    'avec des suggestions personnalisées d’études et des étudiants motivés' +
    ' pour leur en parler.';

  return (
    <NextAuthProvider session={pageProps.session}>
      <ReactQueryProvider client={reactQueryClient}>
        <ChakraProvider theme={theme} resetCSS={false}>
          <Helmet titleTemplate="%s | INSPIRE" defaultTitle={title}>
            {favicons}
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:card" content="summary" />
            {['twitter:description', 'og:description'].map((n) => (
              <meta key={n} property={n} content={description} />
            ))}
            {['twitter:image', 'og:image'].map((n, i) => (
              <meta
                key={i}
                property={n}
                content={`/img/inspire-logo-preview.jpg`}
              />
            ))}
            <meta property="og:image:width" content="600px" />
            <meta property="og:image:height" content="600px" />
          </Helmet>
          <GlobalWrapper>
            {
              // @ts-ignore
              getWrappers(<Page {...pageProps} err={err} />)
            }
          </GlobalWrapper>
          <Crisp />
          {process.env.NEXT_PUBLIC_DISABLE_HOTJAR !== 'true' && <Hotjar />}
          <ToastContainer
            limit={parseInt(
              process.env.NEXT_PUBLIC_REACT_TOASTIFY_LIMIT || '3',
              10
            )}
            position="bottom-center"
            closeButton={false}
            // To be on top of Crisp
            style={{ zIndex: 1000001 }}
          />
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryProvider>
    </NextAuthProvider>
  );
};

export default App;
